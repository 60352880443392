.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color: #fafafa;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: #377484;
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #149279);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #377484;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;

  border-radius: 5px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: #377484;
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
}

.btn:hover {
  background-color: transparent;
  color: #fff;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #1abc9c;
  top: 50%;
  transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: #377484;
}

.text {
  color: #333;
  font-size: 20px;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 18px;
}

.information i {
  color: #377484;
  margin:1px ;
  padding: 3px;
}


.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #1abc9c, #149279);
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #377484;
  border-radius: 50%;
  bottom: 77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #377484, #5accec);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  .square,
  .big-circle {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
}

#clone-container {
  display: none;
}

#main-wrapping-container {
  background-color: #f0f1f0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin: 0;
  left: 0;
  top: 0;
}
#main-wrapping-container #header-container {
  background-color: #2c3e50;
  display: flex;
  align-items: center;
  padding: 10px;
  font-family: roboto;
  min-height: 35px;
}
#main-wrapping-container #header-container #header-title {
  padding: 5px 10px;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ecf0f1;
}
#main-wrapping-container #header-container #next,
#main-wrapping-container #header-container #prev {
  background-color: rgba(180, 180, 180, 0.4);
  color: #1a252f;
  font-weight: bold;
  transition: all 0.3s;
  padding: 15px;
  position: relative;
  font-size: 30px;
  font-weight: bold;
  margin: 0 2px;
  border-radius: 3px;
}
#main-wrapping-container #header-container #next:not(.disabled),
#main-wrapping-container #header-container #prev:not(.disabled) {
  cursor: pointer;
}
#main-wrapping-container #header-container #next:hover:not(.disabled),
#main-wrapping-container #header-container #prev:hover:not(.disabled) {
  background-color: rgba(180, 180, 180, 0.8);
}
#main-wrapping-container #header-container #next.disabled,
#main-wrapping-container #header-container #prev.disabled {
  opacity: 0.2;
  pointer-events: none;
}
#main-wrapping-container #header-container #next::before,
#main-wrapping-container #header-container #prev::before {
  content: "›";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#main-wrapping-container #header-container #prev::before {
  content: "‹";
}
#main-wrapping-container #header-container #fullscreen {
  background-color: rgba(180, 180, 180, 0.4);
  color: black;
  font-weight: bold;
  transition: all 0.3s;
  padding: 15px;
  position: relative;
  font-size: 20px;
  font-weight: bold;
  margin: 0 2px 0 10px;
  border-radius: 3px;
  cursor: pointer;
}
#main-wrapping-container #header-container #fullscreen:hover {
  background-color: rgba(180, 180, 180, 0.8);
}
#main-wrapping-container #header-container #fullscreen::before {
  content: "";
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20height%3D%27100%25%27%20width%3D%27100%25%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%20version%3D%271.1%27%20x%3D%270px%27%20y%3D%270px%27%20viewBox%3D%270%200%20100%20100%27%20style%3D%27enable-background%3Anew%200%200%20100%20100%3B%27%20xml%3Aspace%3D%27preserve%27%3E%3Cpolygon%20style%3D%27%27%20points%3D%2785.669%2C76.831%2071.338%2C62.5%2062.5%2C71.338%2076.831%2C85.669%2062.5%2C100%20100%2C100%20100%2C62.5%20%27%20fill%3D%27rgba%28255%2C%20255%2C%20255%2C%200.5%29%27%3E%3C%2Fpolygon%3E%3Cpolygon%20style%3D%27%27%20points%3D%2737.5%2C71.338%2028.662%2C62.5%2014.331%2C76.831%200%2C62.5%200%2C100%2037.5%2C100%2023.169%2C85.669%20%27%20fill%3D%27rgba%28255%2C%20255%2C%20255%2C%200.5%29%27%3E%3C%2Fpolygon%3E%3Cpolygon%20style%3D%27%27%20points%3D%2737.5%2C0%200%2C0%200%2C37.5%2014.331%2C23.169%2028.527%2C37.354%2037.365%2C28.516%2023.169%2C14.331%20%27%20fill%3D%27rgba%28255%2C%20255%2C%20255%2C%200.5%29%27%3E%3C%2Fpolygon%3E%3Cpolygon%20style%3D%27%27%20points%3D%27100%2C0%2062.5%2C0%2076.831%2C14.331%2062.635%2C28.516%2071.473%2C37.354%2085.669%2C23.169%20100%2C37.5%20%27%20fill%3D%27rgba%28255%2C%20255%2C%20255%2C%200.5%29%27%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
}
#main-wrapping-container #header-container #fullscreen.full::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20height%3D%27100%25%27%20width%3D%27100%25%27%20%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%20viewBox%3D%270%200%20100%20100%27%20version%3D%271.1%27%20xml%3Aspace%3D%27preserve%27%20style%3D%27%27%20x%3D%270px%27%20y%3D%270px%27%20fill-rule%3D%27evenodd%27%20clip-rule%3D%27evenodd%27%20stroke-linejoin%3D%27round%27%20stroke-miterlimit%3D%271.41421%27%3E%3Cg%20transform%3D%27matrix%28-0.987407%2C0.983914%2C-0.983914%2C-0.987407%2C149.367%2C38.4517%29%27%3E%3Cpath%20d%3D%27M14%2C49.333L14%2C39.667L35.5%2C39.667L35.5%2C30L50%2C44.5L35.5%2C59L35.5%2C49.333L14%2C49.333Z%27%20style%3D%27%27%20fill%3D%27rgba%28255%2C%20255%2C%20255%2C%200.5%29%27%3E%3C%2Fpath%3E%3C%2Fg%3E%3Cg%20transform%3D%27matrix%280.987407%2C0.983914%2C0.983914%2C-0.987407%2C-49.367%2C38.4517%29%27%3E%3Cpath%20d%3D%27M14%2C49.333L14%2C39.667L35.5%2C39.667L35.5%2C30L50%2C44.5L35.5%2C59L35.5%2C49.333L14%2C49.333Z%27%20style%3D%27%27%20fill%3D%27rgba%28255%2C%20255%2C%20255%2C%200.5%29%27%3E%3C%2Fpath%3E%3C%2Fg%3E%3Cg%20transform%3D%27matrix%280.987407%2C-0.983914%2C0.983914%2C0.987407%2C-49.367%2C61.5483%29%27%3E%3Cpath%20d%3D%27M14%2C49.333L14%2C39.667L35.5%2C39.667L35.5%2C30L50%2C44.5L35.5%2C59L35.5%2C49.333L14%2C49.333Z%27%20style%3D%27%27%20fill%3D%27rgba%28255%2C%20255%2C%20255%2C%200.5%29%27%3E%3C%2Fpath%3E%3C%2Fg%3E%3Cg%20transform%3D%27matrix%28-0.987407%2C-0.983914%2C-0.983914%2C0.987407%2C149.367%2C61.5483%29%27%3E%3Cpath%20d%3D%27M14%2C49.333L14%2C39.667L35.5%2C39.667L35.5%2C30L50%2C44.5L35.5%2C59L35.5%2C49.333L14%2C49.333Z%27%20style%3D%27%27%20fill%3D%27rgba%28255%2C%20255%2C%20255%2C%200.5%29%27%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
#main-wrapping-container #header-container #counter {
  margin-left: auto;
  margin-right: 10px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5px 15px;
  cursor: default;
  font-size: 18px;
  color: #1a252f;
}
#main-wrapping-container #header-container #counter span {
  margin: 0 2px;
}
#main-wrapping-container .elements-container {
  flex-grow: 1;
  overflow: hidden;
}
#main-wrapping-container .elements-container .single-section {
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateX(0);
  position: relative;
}
#main-wrapping-container .elements-container .single-section:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.7);
}
#main-wrapping-container .elements-container .single-section > .single-element {
  transition: transform 0.4s;
}
#main-wrapping-container .elements-container .single-section[data-title]::before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: rgba(180, 180, 180, 0.5);
  color: #2c3e50;
  transition: all 0.3s;
  content: attr(data-title);
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-family: Roboto;
}
#main-wrapping-container .elements-container .single-section:hover::before {
  transform: translateY(-100%);
}
#main-wrapping-container .elements-container .single-section:hover > .single-element {
  transform: translateY(-10%);
}
#main-wrapping-container .elements-container.columns .single-section {
  width: 25%;
  height: 100%;
  overflow: hidden;
  display: flex;
  float: left;
  justify-content: center;
  align-items: center;
}
#main-wrapping-container .elements-container.columns[data-count="1"] .single-section {
  width: 100%;
}
#main-wrapping-container .elements-container.columns[data-count="3"] .single-section {
  width: calc(100% / 3);
}
#main-wrapping-container .elements-container.grid .single-section {
  width: 50%;
  height: 50%;
  overflow: hidden;
  display: flex;
  float: left;
  justify-content: center;
  align-items: center;
}
#main-wrapping-container .elements-container.grid[data-count="4"] .single-section:nth-child(3) {
  background-color: rgba(255, 255, 255, 0.3);
}
#main-wrapping-container .elements-container.grid[data-count="4"] .single-section:nth-child(4) {
  background-color: rgba(255, 255, 255, 0.7);
}
#main-wrapping-container .elements-container .single-element {
  position: relative;
  margin: 20px;
  float: left;
}
#main-wrapping-container .elements-container .styles-container {
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translateX(-50%);
}
#main-wrapping-container .elements-container .styles-container span {
  height: 1.6rem;
  width: 1.6rem;
  margin: 0 0.5rem;
  border-radius: 20%;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
#main-wrapping-container .elements-container .styles-container span.active {
  box-shadow: 0 0 0rem 0.1rem rgba(250, 250, 250, 0.5), 0 0 0rem 0.3rem rgba(0, 0, 0, 0.2);
}
#main-wrapping-container .elements-container .layouts-container {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}
#main-wrapping-container .elements-container .layouts-container span {
  height: 1rem;
  width: 1rem;
  margin: 0.3rem 0;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
  background-color: #bdc3c7;
  position: relative;
}
#main-wrapping-container .elements-container .layouts-container span:hover::before {
  opacity: 1;
  transform: translate(-5%, -50%);
}
#main-wrapping-container .elements-container .layouts-container span::before {
  content: attr(data-title);
  position: absolute;
  right: 110%;
  top: 50%;
  transform: translate(10%, -50%);
  padding: 0.3rem 0.7rem;
  background-color: #bdc3c7;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  font-family: Roboto;
  text-transform: uppercase;
  pointer-events: none;
  transition: all 0.3s;
  opacity: 0;
}
#main-wrapping-container .elements-container .layouts-container span.active {
  background-color: #949da4;
}

@media (orientation: portrait) {
  #main-wrapping-container .elements-container.grid[data-count="6"] .single-section {
    width: calc(100% / 2);
    height: calc(100% / 3);
  }
  #main-wrapping-container .elements-container.grid[data-count="6"] .single-section:nth-child(3) {
    background-color: rgba(255, 255, 255, 0.3);
  }
  #main-wrapping-container .elements-container.grid[data-count="6"] .single-section:nth-child(4) {
    background-color: rgba(255, 255, 255, 0.7);
  }
}
@media (orientation: landscape) {
  #main-wrapping-container .elements-container.grid[data-count="6"] .single-section {
    width: calc(100% / 3);
    height: calc(100% / 2);
  }
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 2rem;
}
.gallery__item {
  box-shadow: 0 0.1875rem 0.625rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
.gallery__image {
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  height: 100%;
  width: 100%;
}
.gallery__image:hover {
  cursor: pointer;
  opacity: 0.7;
  transform: scale(1.15);
}
.gallery__image:hover + .gallery__image__caption {
  display: block;
}
.gallery__image__caption {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background: #222;
  border-radius: 0.375rem;
  color: #fefefe;
  font-weight: 600;
  margin-top: 1.25rem;
  padding: 1rem;
  white-space: nowrap;
  z-index: 1;
}
.gallery__navigation--next, .gallery__navigation--prev, .gallery__navigation--close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-size: 0;
  height: 42px;
  opacity: 0.5;
  overflow: hidden;
  padding: 0;
  transition: opacity 0.2s ease-in-out;
  width: 42px;
}
.gallery__navigation--next:hover, .gallery__navigation--prev:hover, .gallery__navigation--close:hover {
  opacity: 1;
}
.gallery__navigation--next::before, .gallery__navigation--prev::before {
  display: flex;
  content: "";
  border: solid #fff;
  border-width: 0 0.25rem 0.25rem 0;
  padding: 0.5rem;
}
.gallery__navigation--next {
  right: 1.5rem;
  transform: rotate(-45deg);
}
.gallery__navigation--prev {
  left: 1.5rem;
  transform: rotate(135deg);
}
.gallery__navigation--close {
  top: 2rem;
  right: 2rem;
}
.gallery__navigation--close::before {
  position: absolute;
  left: 1rem;
  background-color: #fff;
  content: " ";
  height: 33px;
  transform: rotate(45deg);
  width: 2px;
}
.gallery__navigation--close::after {
  position: absolute;
  left: 1rem;
  background-color: #fff;
  content: " ";
  height: 33px;
  transform: rotate(-45deg);
  width: 2px;
}
.gallery__modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  inset: 0;
  overflow-y: scroll;
  width: 100%;
  z-index: 1;
}
.gallery__modal img {
  display: block;
  margin: auto;
  max-width: 90%;
  max-height: 90%;
}
.gallery__modal[aria-hidden=true] {
  display: none;
}
.gallery__modal[aria-hidden=false] {
  display: flex;
}

.noscroll {
  overflow: hidden;
}

/* GENERAL STYLES */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(130deg, #dfdfdf 0%, #dcf3f7 50%, #0485cb 100%);
  font-family: "Montserrat", Verdana, Geneva, sans-serif;
  line-height: 1.4;
  min-height: 100vh;
  text-shadow: 1px 1px 2px #ccc;
}

/* Navigation Menu */
.navigation {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0);
  margin-right: 2rem;
  text-align: right;
  height: 5rem;
  width: auto;
  z-index: 1;
}
.navigation__link {
  font-size: 1.5rem;
  color: #fff;
  margin-left: 1.25rem;
  text-decoration: none;
  text-shadow: 0 0 0.375rem #222222;
  transition: color 0.4s ease;
}
.navigation__link:hover {
  color: #ffa600;
  text-shadow: 0.0625rem 0.0625rem 0.125rem #222222;
}

/* Content */
/* .page {
  margin: 1rem auto;
  max-width: 100rem;
} */

/* .container {
  margin-top: 2rem;
  padding: 0 1rem 2rem;
  max-width: 75rem;
} */

.title {
  font-weight: bold;
  font-size: 2rem;
  margin: 1rem 0;
  text-align: center;
}

.paragraph {
  margin-bottom: -1.625rem;
}

/* Footer */
.footer {
  background: linear-gradient(130deg, #554D4A 0%, #888 60%, #524B45 100%);
  box-shadow: 0 -0.25rem 0.25rem rgba(0, 0, 0, 0.5);
  color: #ffa600;
  font-size: 1rem;
  text-shadow: 0.0625rem 0.0625rem 0.125rem #222222;
}
.footer__container {
  display: grid;
  margin: 0 auto;
  max-width: 75rem;
}
.footer__column {
  border-left: 0.0625rem solid #bbb;
  margin: 1.5rem 0;
  padding: 0 1rem;
}
.footer__column:first-child {
  border: 0;
}
.footer__column > ul > li {
  list-style-type: none;
  margin: 0.1875rem 0;
}
.footer__link {
  color: #fefefe;
  text-decoration: none;
  transition: color 0.4s ease, -webkit-text-decoration 0.4s ease;
  transition: color 0.4s ease, text-decoration 0.4s ease;
  transition: color 0.4s ease, text-decoration 0.4s ease, -webkit-text-decoration 0.4s ease;
}
.footer__link:hover {
  color: #00d4ff;
  text-decoration: underline;
}
.footer__sub {
  background: linear-gradient(130deg, #554D4A 0%, #444 50%, #524B45 100%);
  font-size: 0.75rem;
  padding: 1rem 2rem;
  text-align: center;
}

@media only screen and (max-width: 575px) {
  .footer__container {
    grid-template-columns: repeat(1, 1fr);
  }
  .footer__column {
    border-left: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 1023px) {
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }
  .footer__column:nth-child(odd) {
    border-left: none;
  }
}
@media only screen and (min-width: 1024px) {
  .footer__container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.input::placeholder {
  color: #fff;  /* Change placeholder color to white */
}